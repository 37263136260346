<template>
  <div>
    <div class="commonBg" ref="commonBg">
      <commonHeader
        :title="phTitle"
        title2="Health Support"
        :subTitle="phSubTitle"
        :imgPath="imgPath"
        :headerText="headerText"
        :imgMode="'1'"
        :mode="1"
      ></commonHeader>
      <commonCover></commonCover>
      <div class="fixed-text bahnschrift-Light-Condensde" ref="fixed_text">
        Health Support
      </div>

      <div class="main">
        <div class="container">

          <div class="intro-title">
            <div class="intro-title-text2 start Meiryo-Bold" ref="subTitle1">
              <span>末永く、心豊かな人生を</span>
            </div>
            <div class="display-flex flex-wrap-wrap">
              <div
                class="intro-title-text1 bahnschrift-Light-Condensde"
                ref="title2"
              >
                <span>PYRROVITAL PRO</span>
              </div>
              <div class="title2-right intro-title-text2 Meiryo-Bold" ref="subTitle2">
                <span>ピローバイタル プロ</span>
              </div>
            </div>
          </div>
          <commonProduct
            :proText="proText1"
            :mauText="mauText1"
            :proImg="proImg1"
            :proIdx="proIdx1"
            :txtAddi="txtAddi1"
            :mode="0"
          ></commonProduct>
          <div class="line"></div>

          <!---------------------------------------------------------------------->
          <div class="intro-title">
            <div class="intro-title-text2 Meiryo-Bold" ref="subTitle1">
              <span>男性特有のお悩みへアプローチ</span>
            </div>
            <div class="display-flex flex-wrap-wrap">
              <div
                class="intro-title-text1 bahnschrift-Light-Condensde"
                ref="title2"
              >
                <span>ENERGY VITAL</span> 
              </div>
              <div class="title2-right intro-title-text2 Meiryo-Bold" ref="subTitle2">
                <span>エナジーバイタル</span>
              </div>
            </div>
          </div>
          <commonProduct
            :proText="proText2"
            :mauText="mauText2"
            :proImg="proImg2"
            :proIdx="proIdx2"
            :txtAddi="txtAddi2"
            :mode="0"
          ></commonProduct>
          <div class="line"></div>

          <!---------------------------------------------------------------------->
          <div class="intro-title">
            <div class="intro-title-text2 Meiryo-Bold" ref="subTitle1">
              <div>シャープで充実した日常のために</div>
            </div>
            <div class="display-flex flex-wrap-wrap">
              <div
                  class="intro-title-text1 bahnschrift-Light-Condensde"
                  ref="title2"
              >
                <span>BRAINESVITAL</span>
              </div>
              <div class="title2-right intro-title-text2 Meiryo-Bold" ref="subTitle2">
                <span>ブレインズバイタル</span>
              </div>
            </div>
          </div>
          <commonProduct
              :proText="proText5"
              :mauText="mauText5"
              :proImg="proImg5"
              :proIdx="proIdx5"
              :txtAddi="txtAddi5"
              :mode="0"
          ></commonProduct>
          <div class="line"></div>

          <!---------------------------------------------------------------------->
          <div class="intro-title">
            <div class="intro-title-text2 Meiryo-Bold" ref="subTitle1">
              <div>流れを味方に、滞りない日々を</div>
            </div>
            <div class="display-flex flex-wrap-wrap">
              <div
                  class="intro-title-text1 bahnschrift-Light-Condensde"
                  ref="title2"
              >
                <span>VESSELVITAL</span>
              </div>
              <div class="title2-right intro-title-text2 Meiryo-Bold" ref="subTitle2">
                <span>ベセルバイタル</span>
              </div>
            </div>
          </div>
          <commonProduct
              :proText="proText6"
              :mauText="mauText6"
              :proImg="proImg6"
              :proIdx="proIdx6"
              :txtAddi="txtAddi6"
              :mode="0"
          ></commonProduct>
          <div class="line"></div>

          <!---------------------------------------------------------------------->
          <div class="intro-title">
            <div class="intro-title-text2 Meiryo-Bold" ref="subTitle1">
              <span>女性ならではのお悩みをケア</span>
            </div>
            <div class="display-flex flex-wrap-wrap">
              <div
                class="intro-title-text1 bahnschrift-Light-Condensde"
                ref="title2"
              >
                <span>OVAVITAL</span>
              </div>
              <div class="title2-right intro-title-text2 Meiryo-Bold" ref="subTitle2">
                <span>オーババイタル</span>
              </div>
            </div>
          </div>
          <commonProduct
            :proText="proText3"
            :mauText="mauText3"
            :proImg="proImg3"
            :proIdx="proIdx3"
            :txtAddi="txtAddi3"
            :mode="1"
          ></commonProduct>
          <div class="line"></div>

          <!---------------------------------------------------------------------->
          <div class="intro-title">
            <div class="intro-title-text2 Meiryo-Bold" ref="subTitle1">
              <div>いつまでも、どこまでも</div>
              <div>自由に歩み続ける人生をサポート</div>
            </div>
            <div class="display-flex flex-wrap-wrap">
              <div
                class="intro-title-text1 bahnschrift-Light-Condensde"
                ref="title2"
              >
                <span>WALK VITAL</span>
              </div>
              <div class="title2-right intro-title-text2 Meiryo-Bold" ref="subTitle2">
                <span>ウォークバイタル</span>
              </div>
            </div>
          </div>
          <commonProduct
            :proText="proText4"
            :mauText="mauText4"
            :proImg="proImg4"
            :proIdx="proIdx4"
            :txtAddi="txtAddi4"
            :mode="0"
          ></commonProduct>

        </div>
      </div>
      <div class="logo-area">
        <div class="normal-logo logoEnd" ref="logoEnd">
          <img class="proHea-logo2" src="@/assets/img/BIOAGE_LOGO.png" alt="" @click="toHome">
        </div>
      </div>
    </div>
    <commonMenu style="z-index: 10"></commonMenu>
    <commonFooter></commonFooter>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { useRouter } from "vue-router";
import commonHeader from "../components/commonHeader.vue";
import commonFooter from "../components/commonFooter.vue";
import commonMenu from "../components/commonMenu.vue";
import commonProduct from "../components/commonProduct.vue";
import commonCover from '../components/commonCover.vue'
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default {
  name: "Products_healthS",
  components: {
    commonHeader,
    commonFooter,
    commonMenu,
    commonProduct,
    commonCover
  },
  setup() {
    const phTitle = ref("Products");
    const phSubTitle = ref("ヘルスサポート");
    const imgPath = ref(require("@/assets/img/m/h_bg.png"));
    const headerText = ref(['心豊かに 健康に 美しく。笑顔溢れる未来のためにも今の一瞬を大切にしたい。',
                            '生命科学とテクノロジーであなたに寄り添う次世代のエイジングケア体験を。']);
    const commonBg = ref(null);
    const title1 = ref(null);
    const title2 = ref(null);
    const subTitle1 = ref(null);
    const subTitle2 = ref(null);
    const fixed_text = ref(null);
    const bigImg1 = ref(null);
    const flogo = ref(null);
    const logoEnd = ref(null);
    const route = useRoute();
    const path = route.path;

    const proText1 = ref([
      "「カラダの変化を感じ始めた時」が、",
      "&nbsp;&nbsp;エイジングケア*のベストタイミング。",
      "　",
      "&nbsp;&nbsp;いつも、いつまでも",
      "&nbsp;&nbsp;自分らしく輝く毎日のために。",
      "　",
      "&nbsp&nbsp&nbsp<span style='font-size: 10px;'>*年齢に応じたケアのこと</span>"
    ]);
    const mauText1 = ref([
      "β-NMN（β-ニコチンアミドモノヌクレオチド）、PQQ（ピロロキノリンキノン）、コエンザイムQ10を中心とした複合成分で、若々しく健康的な毎日をトータルサポート。",
    ]);
    const proImg1 = ref(require("@/assets/img/m/PYRROVITAL-PRO.png"));
    const proIdx1 = ref("1");
    const txtAddi1 = ref([
      "<div style='line-height: 1.8;font-size: 16px'>¥32,940（税込）</div>",
      "　",
      "<strong>[内容量]</strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style='line-height: 1.8;font-size: 16px'>60粒</div>",
      "　",
      "<strong><div style='margin-bottom: 8px'>[主要成分]</div></strong>",
      "<div style='line-height: 1.2;font-size: 16px'>β-NMN (β-ニコチンアミドモノヌクレオチド）※純度99.9%以上、コエンザイムQ10、カラマツエキス、</div>",
      "<div style='line-height: 1.2;font-size: 16px'>サフランエキス、PQQ (ピロロキノリンキノン) 、紫茶エキス、オリーブ葉エキス、ヒハツエキス</div>",
      "　",
      "<strong><div style='margin-bottom: 8px'>[お摂りいただきたい方]</div></strong>",
      "<div style='line-height: 1.8;font-size: 16px'>・エイジングケア*に関心のある方</div>",
      "<div style='line-height: 1.8;font-size: 16px'>*年齢に応じたケアのこと</div>",
      "<div style='line-height: 1.8;font-size: 16px'>・エイジングサインが気になる方</div>",
      "<div style='line-height: 1.8;font-size: 16px'>・性別を問わず、様々なライフスタイルの方におすすめです</div>",
      "　",
      "<strong><div style='margin-bottom: 8px'>[召し上がり方]</div></strong>",
      "<div style='line-height: 1.2;font-size: 16px'>1日2～3粒を目安に水またはぬるま湯と共に召し上がりください。</div>",
    ]);

    const proText2 = ref([
      "情熱とパワーは、あなたをもっと強くする。", 
      "エナジーに満ち溢れた毎日を。", 
      "　", 
      "心も身体も若さを失わないあなたへ。", 
      /*"心も身体も若々しいあなたへ", */
    ]);
    const mauText2 = ref([
      "年齢を重ねると共に体内から失われる成分へのアプローチで、より生き生きと充実した日々をサポート。不足しがちな成分を補給し、バランスを取ることで健康的で活力に満ちた生活を。",
    ]);
    const proImg2 = ref(require("@/assets/img/m/ENERGY.png"));
    const proIdx2 = ref("2");
    const txtAddi2 = ref([
      "<div style='line-height: 1.8;font-size: 16px'>¥18,900（税込）</div>",
      "　",
      "<strong>[内容量]</strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style='line-height: 1.8;font-size: 16px'>90粒</div>",
      "　",
      "<strong><div style='margin-bottom: 8px'>[主要成分]</div></strong>",
      "<div style='line-height: 1.2;font-size: 16px'>L-アルギニン、亜鉛含有酵母、黒ショウガエキス、シラジットエキス、ブロッコリースプラウトエキス、</div>",
      "<div style='line-height: 1.2;font-size: 16px'>コエンザイムQ10、葉酸</div>",
      "　",
      "<strong><div style='margin-bottom: 8px'>[お摂りいただきたい方]</div></strong>",
      "<div style='line-height: 1.8;font-size: 16px'>・35歳以上の男性</div>",
      "<div style='line-height: 1.8;font-size: 16px'>・男性らしさの維持に関心のある方</div>",
      "<div style='line-height: 1.8;font-size: 16px'>・フィットネス、スポーツに取り組まれている方</div>",
      "　",
      "<strong><div style='margin-bottom: 8px'>[召し上がり方]</div></strong>",
      "<div style='line-height: 1.2;font-size: 16px'>1日3粒を目安に水またはぬるま湯と共に召し上がりください。</div>",
    ]);

    const proText3 = ref([
      "女性としての自分を慈しむことで",
      "忙しい日々の中でも、自分らしく。",
      "　",
      "変化する自分と共に",
      "凛と、しなやかに生きる。",
    ]);
    const mauText3 = ref([
      "植物性イソフラボンを含むレッドクローバーを中心とした複合成分で、女性のお悩みに寄り添う。",
      "　",
      "生涯を通じさまざまな身体的な変化やホルモンの変動に直面する女性をサポート。",
    ]);
    const proImg3 = ref(require("@/assets/img/m/H1.png"));
    const proIdx3 = ref("3");
    const txtAddi3 = ref([
      "<div style='line-height: 1.8;font-size: 16px'>¥32,940（税込）</div>",
      "　",
      "<strong>[内容量]</strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style='line-height: 1.8;font-size: 16px'>2g×30本</div>",
      "　",
      "<strong><div style='margin-bottom: 8px'>[主要成分]</div></strong>",
      "<div style='line-height: 1.2;font-size: 16px'>ヤマイモ抽出物、アスタキサンチン、レッドクローバーエキス、乳酸菌混合末、</div>",
      "<div style='line-height: 1.2;font-size: 16px'>亜鉛含有酵母、PQQ (ピロロキノリンキノン) 、サフランエキス、葉酸</div>",
      "　",
      "<strong><div style='margin-bottom: 8px'>[お摂りいただきたい方]</div></strong>",
      "<div style='line-height: 1.8;font-size: 16px'>・35歳以上の女性</div>",
      "<div style='line-height: 1.8;font-size: 16px'>・月のリズムで体調の変化を感じる方</div>",
      "<div style='line-height: 1.8;font-size: 16px'>・歳と共に女性特有の身体の変化を感じるようになった方</div>",
      "　",
      "<strong><div style='margin-bottom: 8px'>[召し上がり方]</div></strong>",
      "<div style='line-height: 1.2;font-size: 16px'>1日1本を目安に水またはぬるま湯と共に召し上がりください。</div>",
    ]);

    const proText4 = ref([
      "新しい一歩、最後に踏み出したのはいつだったか。",
      "最初の一歩、それは小さくて、大きな一歩。",
      "　",
      "いつもより遠回りして、知らない場所へ足を伸ばす。",
      "心が昂揚するままに。",
      "　",
      "出かけよう、この先へ。",
      "新しい世界が広がっている。",
    ]);
    const mauText4 = ref([
      "グルコサミン、サメ軟骨エキス(コンドロイチン含有)、鮭鼻軟骨エキス(プロテオグリカン含有) などの複合成分で自由で快適な毎日を応援。アクティブでより充実したライフスタイルを。",
    ]);
    const proImg4 = ref(require("@/assets/img/m/WALK.png"));
    const proIdx4 = ref("4");
    const txtAddi4 = ref([
      "<div style='line-height: 1.8;font-size: 16px'>¥13,500（税込）</div>",
      "　",
      "<strong>[内容量]</strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style='line-height: 1.8;font-size: 16px'>120粒</div>",
      "　",
      "<strong><div style='margin-bottom: 8px'>[主要成分]</div></strong>",
      "<div style='line-height: 1.2;font-size: 16px'>グルコサミン、MSM(メチルサルフォニルメタン。硫黄含有)、サメ軟骨エキス(コンドロイチン含有)、鮭鼻軟骨エキス(プロテオグリカン含有)、トリ軟骨エキス(非変性II型コラーゲン含有)、ウコンエキス(クルクミン含有)</div>",
      "　",
      "<strong><div style='margin-bottom: 8px'>[お摂りいただきたい方]</div></strong>",
      "<div style='line-height: 1.8;font-size: 16px'>・階段がつらい方</div>", 
      "<div style='line-height: 1.8;font-size: 16px'>・立ち仕事が多い方</div>",
      "<div style='line-height: 1.8;font-size: 16px'>・スポーツなどで運動負荷の高い生活を送る方</div>",
      "　",
      "<strong><div style='margin-bottom: 8px'>[召し上がり方]</div></strong>",
      "<div style='line-height: 1.2;font-size: 16px'>1日4～6粒を目安に水またはぬるま湯と共に召し上がりください。</div>",
    ]);

    const proText5 = ref([
      "積み重ねてきた記憶と思考の力で",
      "自分らしい未来を創る。",
    ]);
    const mauText5 = ref([
      "イチョウ葉、鶏由来プラズマローゲン、PQQなどの成分で冴えた毎日にアプローチ。",
      "現代の忙しい日々の中で求められる集中力・記憶力・クリアな思考をサポート。"
    ]);
    const proImg5 = ref(require("@/assets/img/Mtv4gw29cHnBx3uGA-jMEUtr.png"));
    const proIdx5 = ref("5");
    const txtAddi5 = ref([
      "<div style='line-height: 1.8;font-size: 16px'>¥20,520（税込）</div>",
      "　",
      "<strong>[内容量]</strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style='line-height: 1.8;font-size: 16px'>3粒×30包</div>",
      "　",
      "<strong><div style='margin-bottom: 8px'>[主要成分]</div></strong>",
      "<div style='line-height: 1.2;font-size: 16px'>イチョウ葉エキス、PQQ(ピロロキノリンキノン)、ビタミンB12、葉酸、ビタミンB6、ビタミンB1、鶏由来プラズマローゲン、ビオチン含有酵母、セレン含有酵母</div>",
      "　",
      "<strong><div style='margin-bottom: 8px'>[お摂りいただきたい方]</div></strong>",
      "<div style='line-height: 1.8;font-size: 16px'>・記憶、思考に関心のある方</div>",
      "<div style='line-height: 1.8;font-size: 16px'>・高い集中力、判断力が求められる方</div>",
      "　",
      "<strong><div style='margin-bottom: 8px'>[召し上がり方]</div></strong>",
      "<div style='line-height: 1.2;font-size: 16px'>1日1包(3粒)を目安に水またはぬるま湯と共に召し上がりください。</div>",
    ]);

    const proText6 = ref([
      "確かなリズムとスムーズな流れで",
      "エネルギーの行き届いた毎日を。",
    ]);
    const mauText6 = ref([
      "エンジュつぼみエキス(ケルセチン含有)、ブロッコリースプラウトエキス、ナットウキナーゼなどの成分を最適なバランスで配合。体の内側から健康と調和することで、巡る毎日をサポート。",
    ]);
    const proImg6 = ref(require("@/assets/img/S3lv0DnrhkVCJHSxzhps56_j.png"));
    const proIdx6 = ref("6");
    const txtAddi6 = ref([
      "<div style='line-height: 1.8;font-size: 16px'>¥18,900（税込）</div>",
      "　",
      "<strong>[内容量]</strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style='line-height: 1.8;font-size: 16px'>180粒</div>",
      "　",
      "<strong><div style='margin-bottom: 8px'>[主要成分]</div></strong>",
      "<div style='line-height: 1.2;font-size: 16px'>エンジュつぼみエキス(ケルセチン含有)、ブロッコリースプラウトエキス、ナットウキナーゼ</div>",
      "　",
      "<strong><div style='margin-bottom: 8px'>[お摂りいただきたい方]</div></strong>",
      "<div style='line-height: 1.8;font-size: 16px'>・巡り、流れに関心のある方</div>",
      "<div style='line-height: 1.8;font-size: 16px'>・冷えが気になる方</div>",
      "　",
      "<strong><div style='margin-bottom: 8px'>[召し上がり方]</div></strong>",
      "<div style='line-height: 1.2;font-size: 16px'>1日6粒を目安に水またはぬるま湯と共に召し上がりください。</div>",
    ]);

    onMounted(() => {
      if (path === "/products_healthS") {
        console.log("products_healthS");
        // Get the elements
        const images = document.querySelectorAll(".good-img");
        const titles = document.querySelectorAll(".intro-title-text1");
        const subtitles = document.querySelectorAll(".intro-title-text2");
        const lines = document.querySelectorAll(".line");

        // Animate the titles
        titles.forEach((title) => {
          gsap.from(title, {
            y: 100,
            opacity: 0,
            duration: 0.5,
            lazy: false,
            scrollTrigger: {
              lazy: false,
              trigger: title,
              start: "top 90%",
              toggleActions: "play none play none",
            },
          });
        });

        // Animate the subtitles
        subtitles.forEach((subtitle) => {
          gsap.from(subtitle, {
            y: 100,
            opacity: 0,
            duration: 0.5,
            lazy: false,
            scrollTrigger: {
              lazy: false,
              trigger: subtitle,
              start: "top 90%",
              toggleActions: "play none play none",
            },
          });
        });

        // Animate the images
        images.forEach((image) => {
          gsap.from(image, {
            y: 100,
            opacity: 0,
            duration: 0.5,
            lazy: false,
            scrollTrigger: {
              lazy: false,
              trigger: image,
              start: "top 90%",
              toggleActions: "play none play none",
            },
          });
        });

        // Animate the lines
        lines.forEach((line) => {
          gsap.from(line, {
            y: 100,
            opacity: 0,
            duration: 0.5,
            lazy: false,
            scrollTrigger: {
              lazy: false,
              trigger: line,
              start: "top 90%",
              toggleActions: "play none play none",
            },
          });
        });

        gsap.to(fixed_text.value, {
          opacity: 0.8,
          scrollTrigger: {
            trigger: ".start",
            start: "top center",
            end: "center center",
            scrub: 1,
            toggleActions: "play none none reverse",
          },
        });

        gsap.to(flogo.value, {
          opacity: 0,
          duration: 0.001,
          scrollTrigger: {
            trigger: logoEnd.value,
            start: "bottom-=50 bottom-=50",
            end: "bottom-=50 bottom-=50",
            toggleActions: "play none none reverse",
            // markers: true,
          },
        });

        gsap.to(logoEnd.value, {
          opacity: 1,
          duration: 0.001,
          scrollTrigger: {
            trigger: logoEnd.value,
            start: "bottom-=50 bottom-=50",
            end: "bottom-=50 bottom-=50",
            toggleActions: "play none none reverse",
            // markers: true,
          },
        });
      }
    });

    const mouseEnterBigImg = (index) => {
      if (document.documentElement.clientWidth > 852) {
        gsap.to(".bigImg" + index, {
          y: -10,
          // boxShadow: "6px 6px 24px #bbbbbb",
          duration: 0.5,
        });
      }
    };

    const mouseLeaveBigImg = (index) => {
      if (document.documentElement.clientWidth > 852) {
        gsap.to(".bigImg" + index, {
          y: 0,
          // boxShadow: "6px 6px 14px #e6e6e6",
          duration: 0.5,
        });
      }
    };

    const router = useRouter();

    const toHome = () => {
      router.push('/');
    };

    return {
      fixed_text,
      bigImg1,
      phTitle,
      phSubTitle,
      imgPath,
      headerText,
      commonBg,
      title1,
      title2,
      subTitle1,
      subTitle2,
      flogo,
      logoEnd,
      proText1,
      mauText1,
      proImg1,
      proIdx1,
      txtAddi1,
      proText2,
      mauText2,
      proImg2,
      proIdx2,
      txtAddi2,
      proText3,
      mauText3,
      proImg3,
      proIdx3,
      txtAddi3,

      proText4,
      mauText4,
      proImg4,
      proIdx4,
      txtAddi4,

      proText5,
      mauText5,
      proImg5,
      proIdx5,
      txtAddi5,

      proText6,
      mauText6,
      proImg6,
      proIdx6,
      txtAddi6,

      mouseEnterBigImg,
      mouseLeaveBigImg,
      toHome,
    };
  },
};
</script>

<style lang="scss" scoped>
@media screen and (min-width: 1030px) {
  @import "@/styles/mediaHealthS_min1030.scss";
}
@media screen and (max-width: 1030px) and (min-width: 870px) {
  @import "@/styles/mediaHealthS_max1029.scss";
}
@media screen and (max-width: 870px) {
  @import "@/styles/mediaHealthS_max870.scss";
}
</style>