<template>
  <div>
    <div class="commonBg" ref="commonBg">
      <commonHeader
        :title="phTitle"
        title2="Beauty Support"
        :subTitle="phSubTitle"
        :imgPath="imgPath"
        :imgMode="'1'"
        :mode="2"
      ></commonHeader>
      <commonCover></commonCover>
      <div
        class="proBea-fixtext fixtext bahnschrift-Light-Condensde" ref="fixtext">
        Beauty Support
      </div>
<!--      <div class="fixed-logo">
        <img class="proBea-logo flogo" src="@/assets/img/BIOAGE_LOGO.png" alt="" ref="flogo" @click="toHome">
      </div>-->

      <div class="proBea-main">
        <div class="proBea-container">
          <!-- <div class="p-tip Meiryo">
            <div>美しくあることは、自分らしくあること</div>
            <div>あなたらしく輝くための「美」へアプローチ</div>
          </div> -->
          <div class="proBea-item1">
            <div class="proBea-item-title">
              <div class="proBea-title-text2 Meiryo-Bold">
                <span>あなたの内側の「白」を呼び醒ます</span>
              </div>
              <!-- <div class="proBea-title-text2 Meiryo-Bold">
                <span>みずみずしい透輝肌</span>
              </div> -->
              <div class="display-flex flex-wrap-wrap">
                <div class="proBea-title-text1 start bahnschrift-Light-Condensde">
                  <span>PUROL</span>
                </div>
                <div class="title2-right proBea-title-text2 Meiryo-Bold">
                  <span>ピュロー</span>
                </div>
              </div>
            </div>
            <!-- <div
              class="proBea-item-image1 imgStart1 bigImage1"
              id="scrollStart"
              @mouseenter="() => mouseEnterBigImg(1)"
              @mouseleave="() => mouseLeaveBigImg(1)"
              ref="imgStart1"
            >
              <img src="@/assets/img/m/PUROL2.png" alt="" />
            </div> -->
            <commonProduct
              :proText="proText1"
              :mauText="mauText1"
              :proImg="proImg1"
              :proIdx="proIdx1"
              :txtAddi="txtAddi1"
              :mode="0"
            ></commonProduct>
          </div>

          <div class="line"></div>

          <div class="proBea-item2">
            <div class="proBea-item-title">
              <div class="proBea-title-text2 Meiryo-Bold">
                <span>心まで弾む</span>
              </div>
              <div class="proBea-title-text2 Meiryo-Bold">
                <span>内側から「肌」にアプローチ</span>
              </div>
              <div class="display-flex flex-wrap-wrap">
                <div class="proBea-title-text1 bahnschrift-Light-Condensde">
                  <span>PUROL AGcode Drink</span>
                </div>
                <div class="title2-right proBea-title-text2 Meiryo-Bold">
                  <span>ピュロー エージーコード ドリンク</span>
                </div>
              </div>
            </div>
            <!-- <div
              class="proBea-item-image1 bigImage2"
              @mouseenter="() => mouseEnterBigImg(2)"
              @mouseleave="() => mouseLeaveBigImg(2)"
            >
              <img src="@/assets/img/m/PUROL-AGcode.jpg" alt="" />
            </div> -->
            <commonProduct
              :proText="proText2"
              :mauText="mauText2"
              :proImg="proImg2"
              :proIdx="proIdx2"
              :txtAddi="txtAddi2"
              :mode="1"
            ></commonProduct>
          </div>
        </div>
      </div>
      <div class="logo-area">
        <div class="normal-logo logoEnd" ref="logoEnd">
          <img class="proBea-logo2" src="@/assets/img/BIOAGE_LOGO.png" alt="" @click="toHome">
        </div>
      </div>
    </div>
    <commonMenu style="z-index: 10"></commonMenu>
    <commonFooter></commonFooter>
  </div>
</template>

<script>
import { onMounted, ref, nextTick } from "vue";
import { useRoute } from "vue-router";
import { useRouter } from "vue-router";
import commonHeader from "../components/commonHeader.vue";
import commonFooter from "../components/commonFooter.vue";
import commonMenu from "../components/commonMenu.vue";
import commonProduct from "../components/commonProduct.vue";
import commonCover from '../components/commonCover.vue'
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default {
  name: "Products_beautyS",
  components: {
    commonHeader,
    commonFooter,
    commonMenu,
    commonProduct,
    commonCover
  },
  setup() {
    const phTitle = ref("Products");
    const phSubTitle = ref("ビューティサポート");
    const imgPath = ref(require("@/assets/img/m/BEAUTY_TOP.png"));
    const commonBg = ref(null);
    const fixtext = ref(null);
    const imgStart1 = ref(null);
    const imgEnd2 = ref(null);
    const flogo = ref(null);
    const logoEnd = ref(null);
    const route = useRoute();
    const path = route.path;
    const proText1 = ref([
      "澄み切った「白」",
      "みずみずしい透輝肌",
      "　",
      "カラダのすみずみまで届く美しさを。",
    ]);
    const mauText1 = ref([
      "L-シスチン、アーティチョークエキスなどを含む複数の美容成分で内側から「白」にアプローチ。透明感に満ちた輝くような美しさに。",
    ]);
    const proImg1 = ref(require("@/assets/img/v3/PUROL.png"));
    const proIdx1 = ref("1");
    const txtAddi1 = ref([
      "<div style='line-height: 1.8;font-size: 16px'>¥28,620（税込）</div>",
      "　",
      "<strong>[内容量]</strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style='line-height: 1.8;font-size: 16px'>180粒</div>",
      "　",
      "<strong><div style='margin-bottom: 8px'>[主要成分]</div></strong>",
      "<div style='line-height: 1.2;font-size: 16px'>L-シスチン、ビタミンC、α-リポ酸、グルタチオン含有酵母、アーティチョークエキス、メロン果汁濃縮エキス、</div>",
      "<div style='line-height: 1.2;font-size: 16px'>コメ由来グルコシルセラミド</div>",
      "　",
      "<strong><div style='margin-bottom: 8px'>[お摂りいただきたい方]</div></strong>",
      "<div style='line-height: 1.8;font-size: 16px'>・時短で効率よく美容に取り組みたい方</div>",
      "<div style='line-height: 1.8;font-size: 16px'>・透明感が気になる方</div>",
      "<div style='line-height: 1.8;font-size: 16px'>・エイジングケア*に関心のある方</div>",
      "<div style='line-height: 1.8;font-size: 16px'>*年齢に応じたケアのこと</div>",
      "　",
      "<strong><div style='margin-bottom: 8px'>[召し上がり方]</div></strong>",
      "<div style='line-height: 1.2;font-size: 16px'>1日6粒を目安に水またはぬるま湯と共に召し上がりください。</div>",
    ])
    const proText2 = ref([
      "真の美しさは内面からにじみ出るもの。",
      "　",
      "弾む 輝く 透き通る",
      "思わず触れたくなる「肌」",
      "　",
      "あなたの肌で体感してください。",
    ]);
    const mauText2 = ref([
      "年を重ねると共に失われるエラスチンを含む複数の成分で内側から「美」にアプローチ。あなたらしい笑顔溢れる美しさに。",
    ]);
    const proImg2 = ref(require("@/assets/img/m/drink-n.png"));
    const proIdx2 = ref("2");
    const txtAddi2 = ref([
      "<div style='line-height: 1.8;font-size: 16px'>¥13,500（税込）</div>",
      "　",
      "<strong>[内容量]</strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style='line-height: 1.8;font-size: 16px'>50mL×10本/箱</div>",
      "　",
      "<strong><div style='margin-bottom: 8px'>[主要成分]</div></strong>",
      "<div style='line-height: 1.2;font-size: 16px'>カツオ由来エラスチン、アロニアエキス、ハトムギエキス、マンゴスチンエキス、</div>",
      "<div style='line-height: 1.2;font-size: 16px'>バラの花びらエキス、コメ由来グルコシルセラミド</div>",
      "　",
      "<strong><div style='margin-bottom: 8px'>[お摂りいただきたい方]</div></strong>",
      "<div style='line-height: 1.8;font-size: 16px'>・時短で効率よく美容に取り組みたい方</div>",
      "<div style='line-height: 1.8;font-size: 16px'>・ハリ、弾力が気になる方</div>",
      "<div style='line-height: 1.8;font-size: 16px'>・エイジングケア*に関心のある方</div>",
      "<div style='line-height: 1.8;font-size: 16px'>*年齢に応じたケアのこと</div>",
      "　",
      "<strong><div style='margin-bottom: 8px'>[召し上がり方]</div></strong>",
      "<div style='line-height: 1.2;font-size: 16px'>1日1本を目安に、よく冷やし軽く2～3回振ってから召し上がりください。開栓後はすぐに召し上がりください。</div>",
    ])

    onMounted(async () => {
      if (path === "/products_beautyS") {
        console.log("products_beautyS");

        const titles = document.querySelectorAll(".proBea-title-text1");
        const subTitles = document.querySelectorAll(".proBea-title-text2");
        const bigImages = document.querySelectorAll(".proBea-item-image1");
        const lines = document.querySelectorAll(".line");

        titles.forEach((title) => {
          gsap.from(title, {
            y: 100,
            opacity: 0,
            duration: 0.5,
            lazy: false,
            scrollTrigger: {
              lazy: false,
              trigger: title,
              start: "top 90%",
              toggleActions: "play none play none",
            },
          });
        });

        subTitles.forEach((subtitle) => {
          gsap.from(subtitle, {
            y: 100,
            opacity: 0,
            duration: 0.5,
            lazy: false,
            scrollTrigger: {
              lazy: false,
              trigger: subtitle,
              start: "top 90%",
              toggleActions: "play none play none",
            },
          });
        });

        bigImages.forEach((image) => {
          gsap.from(image, {
            y: 100,
            opacity: 0,
            duration: 0.5,
            lazy: false,
            scrollTrigger: {
              lazy: false,
              trigger: image,
              start: "top 90%",
              toggleActions: "play none play none",
            },
          });
        });

        lines.forEach((line) => {
          gsap.from(line, {
            y: 100,
            opacity: 0,
            duration: 0.5,
            lazy: false,
            scrollTrigger: {
              lazy: false,
              trigger: line,
              start: "top 90%",
              toggleActions: "play none play none",
            },
          });
        });

        gsap.to(fixtext.value, {
          opacity: 0.8,
          scrollTrigger: {
            trigger: '.start', // imgStart1.value
            start: "top center",
            end: "center center",
            scrub: 1,
            toggleActions: "play none none reverse",
            // markers: true,
          },
        });

        gsap.to(flogo.value, {
          opacity: 0,
          duration: 0.001,
          scrollTrigger: {
            trigger: logoEnd.value,
            start: "bottom-=50 bottom-=50",
            end: "bottom-=50 bottom-=50",
            toggleActions: "play none none reverse",
            // markers: true,
          },
        });

        gsap.to(logoEnd.value, {
          opacity: 1,
          duration: 0.001,
          scrollTrigger: {
            trigger: logoEnd.value,
            start: "bottom-=50 bottom-=50",
            end: "bottom-=50 bottom-=50",
            toggleActions: "play none none reverse",
            // markers: true,
          },
        });
      }
    });

    const mouseEnterBigImg = (index) => {
      if (document.documentElement.clientWidth > 852) {
        gsap.to(".bigImage" + index, {
          y: -10,
          // boxShadow: "",
          duration: 0.5,
        });
      }
    };

    const mouseLeaveBigImg = (index) => {
      if (document.documentElement.clientWidth > 852) {
        gsap.to(".bigImage" + index, {
          y: 0,
          // boxShadow: "6px 6px 14px #e6e6e6",
          duration: 0.5,
        });
      }
    };

    const router = useRouter();

    const toHome = () => {
      router.push('/');
    };

    return {
      phTitle,
      phSubTitle,
      imgPath,
      commonBg,
      fixtext,
      imgStart1,
      imgEnd2,
      flogo,
      logoEnd,
      proText1,
      mauText1,
      proImg1,
      proIdx1,
      txtAddi1,
      proText2,
      mauText2,
      proImg2,
      proIdx2,
      txtAddi2,
      mouseEnterBigImg,
      mouseLeaveBigImg,
      toHome,
    };
  },
};
</script>

<style lang="scss" scoped>
@media screen and (min-width: 1030px) {
  @import "@/styles/mediaBeautyS_min1030.scss";
}
@media screen and (max-width: 1030px) and (min-width: 870px) {
  @import "@/styles/mediaBeautyS_max1029.scss";
}
@media screen and (max-width: 870px) {
  @import "@/styles/mediaBeautyS_max870.scss";
}
</style>